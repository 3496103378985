import React, { useState } from 'react'
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Answer from './components/Answers';
import Demo from './components/Demo2';
import Header from './components/Header';
import Homepage from "./components/Homepage";
import Result from './components/Result';
import Videos from './components/Videos';
import './styles/style.css'
function App() {
	const [model, setModel] = useState(true)
	// setTimeout(() => {
	// 	setModel(true)
	// }, 2000);
	return(
		<div>
			<BrowserRouter>
			<div>
				<Header />
				{false && 
					<div className='pop-modal'>
						<div className="">
							<img  src="https://i.ibb.co/LkF9DYW/youtube-thumbnail-online-education-promo-pack.jpg" alt="modal" />
							<button onClick={()=>setModel(false)}className='close-m-btn'>X</button>	
						</div>
						
					</div>
				}
				<Switch>
					<Route path='/' component={Homepage} exact />
					<Route path='/videos' component={Videos} /> 
					<Route path='/result' component={Result} />
					<Route path='/answer-keys' component={Answer}  />
					<Route path='/demo' component={Demo}  />
				</Switch>
			</div>
				
			</BrowserRouter>
		</div>
	)
}
export default App;

